import DateRangePickerFilter from '../containers/Filters/types/DateRangePickerFilter';
import DateRangeValue from '../containers/Filters/values/DateRangeValue';

export const FILTER_TYPES = Object.freeze({
  TEXT_INPUT: 0,
  DATE_RANGE: 1,
});

export const FILTERS = {
  plannedPickUpTime: {
    label: 'consignments:filter.options.planned_pickup',
    component: DateRangePickerFilter,
    valueComponent: DateRangeValue,
    width: '328px',
    denormalize: ({ from, to }) => ({ after: from, before: to }),
    normalize: ({ after, before }) => ({ from: after, to: before }),
    removable: false,
  },
  plannedDropOffTime: {
    label: 'consignments:filter.options.planned_drop_off',
    component: DateRangePickerFilter,
    valueComponent: DateRangeValue,
    width: '328px',
    denormalize: ({ from, to }) => ({ after: from, before: to }),
    normalize: ({ after, before }) => ({ from: after, to: before }),
    removable: false,
  },
};

export const OUTBOUND_FILTERS = {
  plannedPickUpTime: FILTERS.plannedPickUpTime,
};

export const INBOUND_FILTERS = {
  plannedDropOffTime: FILTERS.plannedDropOffTime,
};

export const OUTBOUND_FILTER_OPTIONS = Object.keys(OUTBOUND_FILTERS).map((filterName) => ({
  name: filterName,
  ...OUTBOUND_FILTERS[filterName],
}));

export const INBOUND_FILTER_OPTIONS = Object.keys(INBOUND_FILTERS).map((filterName) => ({
  name: filterName,
  ...INBOUND_FILTERS[filterName],
}));
