import pkg from '../../package.json';

export default {
  name: pkg.description,
  baseURL: process.env.REACT_APP_BASE_URL || 'https://dat.local/api',
  ttl: {
    list: 60,
  },
  defaultTimezone: 'Europe/Berlin',
  contact: {
    name: 'Transport Team Seven Senders',
    phone: '+49 30 233218755',
    email: 'operations@sevensenders.com',
    emailCC: 'cop@sevensenders.com',
  },
  policy: {
    code: /^[0-9]{6,}$/,
    username: /^[\p{L}\p{M}\p{S}\p{N}\p{P}]+/u, // refer https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_AdminCreateUser.html#CognitoUserPools-AdminCreateUser-request-Username
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$*.{}?"!@#%&/,><':;|_~`^\][)(]).{6,}/,
  },
};
