import axios from 'axios';
import jwtDecode from 'jwt-decode';
import qs from 'qs';
import EventTarget from '@ungap/event-target';

import TokenExpired from './errors/TokenExpired';
import GenerateTokenFailureEvent from './events/GenerateTokenFailureEvent';
import GenerateTokenSuccessEvent from './events/GenerateTokenSuccessEvent';

export const endpoints = {
  login: '/token',
  refreshToken: '/token/refresh',
  userDetails: '/me',
  forgotPassword: '/forgot-password/request',
  confirmPassword: '/forgot-password/confirmation',
  consignments: '/consignments',
  consignment: '/consignments/{id}',
  exportConsignments: '/consignments/export',
  customerSettings: '/customer/settings',
};

export default class DAT {
  constructor({ baseURL }) {
    this.injectedInterceptors = false;
    this.refreshingToken = false;

    this.axios = axios.create({
      baseURL,
    });

    this.eventEmitter = new EventTarget();

    this.verifyToken = this.verifyToken.bind(this);
    this.onVerifyTokenError = this.onVerifyTokenError.bind(this);
    this.injectInterceptors();
  }

  /**
   * Request interceptor to verify token expiry
   * @param config
   * @returns {*}
   */
  verifyToken(config) {
    if (this.refreshToken && this.token && config.url !== endpoints.refreshToken && config.url !== endpoints.login) {
      const decoded = jwtDecode(this.token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        throw new TokenExpired(config);
      }
    }
    return config;
  }

  /**
   * Request interceptor to catch token refresh error
   * @param error
   * @returns {Promise<*>}
   */
  async onVerifyTokenError(error) {
    if (!this.refreshToken || this.refreshingToken || !(error instanceof TokenExpired)) {
      return Promise.reject(error);
    }

    this.refreshingToken = true;
    try {
      const { data } = await this.generateToken(this.refreshToken);
      this.eventEmitter.dispatchEvent(new GenerateTokenSuccessEvent(data));
    } catch (err) {
      this.eventEmitter.dispatchEvent(new GenerateTokenFailureEvent(err));

      this.refreshingToken = false;
      return Promise.reject(err);
    }

    this.refreshingToken = false;
    const config = error.getLastConfig();
    config.headers.common = { ...config.headers.common, ...this.getHeaders() };
    return config;
  }

  injectInterceptors() {
    if (this.injectedInterceptors) {
      return;
    }

    this.axios.interceptors.request.use((config) => config, this.onVerifyTokenError);
    this.axios.interceptors.request.use(this.verifyToken, (err) => Promise.reject(err));

    this.injectedInterceptors = true;
  }

  getHeaders() {
    return { ...this.axios.defaults.headers.common };
  }

  listen(type, fn) {
    return this.eventEmitter.addEventListener(type, fn);
  }

  /**
   * Set token for all request
   * @param token
   * @param refreshToken
   */
  setToken(token, refreshToken = null) {
    this.token = token;
    this.refreshToken = refreshToken;
    this.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  /**
   * Generate config for request
   * @param cancelToken
   * @returns {{}}
   */
  getConfig({ cancelToken, ...otherConfig }) {
    const config = { ...otherConfig };

    if (cancelToken && cancelToken.token) {
      config.cancelToken = cancelToken.token;
    }

    return config;
  }

  async login(username, password, cancelToken = null) {
    const config = this.getConfig({ cancelToken });

    return await this.axios.post(endpoints.login, { username, password }, config);
  }

  async userDetails(cancelToken = null) {
    const config = this.getConfig({ cancelToken });

    return await this.axios.get(endpoints.userDetails, config);
  }

  async generateToken(refreshToken, cancelToken = null) {
    const config = this.getConfig({ cancelToken });

    return await this.axios.post(endpoints.refreshToken, { refresh_token: refreshToken }, config);
  }

  async forgotPassword(username, cancelToken = null) {
    const config = this.getConfig({ cancelToken });

    return await this.axios.post(endpoints.forgotPassword, { username }, config);
  }

  async confirmPassword(username, code, password, cancelToken = null) {
    const config = this.getConfig({ cancelToken });

    return await this.axios.post(endpoints.confirmPassword, { username, code, password }, config);
  }

  /* istanbul ignore next */
  async getConsignment(id, cancelToken = null) {
    const config = this.getConfig({ cancelToken });
    const url = endpoints.consignment.replace('{id}', id);

    return await this.axios.get(url, config);
  }

  /* istanbul ignore next */
  async getConsignments(filters = {}, cancelToken = null) {
    const config = this.getConfig({ cancelToken });

    const query =
      Object.entries(filters).length > 0
        ? qs.stringify(filters, {
            addQueryPrefix: true,
            arrayFormat: 'brackets',
          })
        : '';

    const url = `${endpoints.consignments}${query}`;
    return await this.axios.get(url, config);
  }

  /* istanbul ignore next */
  async exportConsignments(filters = {}, cancelToken = null) {
    const config = this.getConfig({
      cancelToken,
      responseType: 'blob',
      headers: { Accept: 'text/csv' },
    });

    const query =
      Object.entries(filters).length > 0
        ? qs.stringify(filters, {
            addQueryPrefix: true,
            arrayFormat: 'brackets',
          })
        : '';

    const url = `${endpoints.exportConsignments}${query}`;
    return await this.axios.get(url, config);
  }

  /* istanbul ignore next */
  async updateConsignment(id, data, cancelToken = null) {
    const config = this.getConfig({ cancelToken });
    const url = endpoints.consignment.replace('{id}', id);

    return await this.axios.put(url, data, config);
  }

  async getCustomerSettings(cancelToken = null) {
    const config = this.getConfig({ cancelToken });
    return await this.axios.get(endpoints.customerSettings, config);
  }

  async updateCustomerSettings(data, cancelToken = null) {
    const config = this.getConfig({ cancelToken });
    return await this.axios.put(endpoints.customerSettings, data, config);
  }
}
